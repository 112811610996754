import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IczRoutes} from '|shared';
import {OfficerTransfersTableComponent} from './officer-transfers-table.component';

const routes: IczRoutes = [{ path: '', component: OfficerTransfersTableComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfficerTransfersRoutingModule { }
