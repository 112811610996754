import {Component, inject} from '@angular/core';
import {
  AbstractUnitViewWithTabsComponent,
  GenericSearchService,
  IczSimpleTableDataSource,
  RegistryOfficeTransferView,
  StorageUnitToolbarButtonsService,
  TableWithTabsViewMetadata,
  unwrapSearchContent
} from '|shared';
import {BehaviorSubject} from 'rxjs';
import {ApiElasticsearchService} from '|api/elastic';
import {TabItem} from '@icz/angular-essentials';

enum OfficerTransfersView {
  HANDED_OVER = 'handedOver',
  STORED = 'stored',
  TO_RESOLVE_ISSUES = 'toResolveIssues',
}

interface OfficerTransfersViewMetadata extends TableWithTabsViewMetadata<OfficerTransfersView> {
  tableViewType: RegistryOfficeTransferView;
}

@Component({
  selector: 'icz-officer-transfers-table',
  templateUrl: './officer-transfers-table.component.html',
  styleUrls: ['./officer-transfers-table.component.scss'],
  providers: [
    StorageUnitToolbarButtonsService,
  ],
})
export class OfficerTransfersTableComponent extends AbstractUnitViewWithTabsComponent<OfficerTransfersView, OfficerTransfersViewMetadata> {

  private genericSearchService = inject(GenericSearchService);

  tabs$ = new BehaviorSubject<TabItem<OfficerTransfersView>[]>([
    {
      id: OfficerTransfersView.HANDED_OVER,
      label: 'Předané',
    },
    {
      id: OfficerTransfersView.STORED,
      label: 'Uložené',
    },
    {
      id: OfficerTransfersView.TO_RESOLVE_ISSUES,
      label: 'K nápravě',
    },
  ]);

  protected viewsEnum = OfficerTransfersView;
  protected defaultView = OfficerTransfersView.HANDED_OVER;

  get tableViewType(): RegistryOfficeTransferView {
    return this.activeViewMetadata?.tableViewType!;
  }

  protected readonly RegistryOfficeTransferView = RegistryOfficeTransferView;

  protected getViewMetadata(tabView: OfficerTransfersView, selectorValue: Nullable<number>): Nullable<OfficerTransfersViewMetadata> {
    if (this.isUnitView) {
      const orgUnitViewUrl = selectorValue ? ApiElasticsearchService.ElasticsearchLoadViewForOrgUnitPath : ApiElasticsearchService.ElasticsearchLoadViewPath;
      const orgUnitId = String(selectorValue);
      let viewName!: string;

      switch (tabView) {
        case OfficerTransfersView.HANDED_OVER:
          viewName = 'transfers-handed-over-registry-office';

          return {
            tabView,
            dataSource: new IczSimpleTableDataSource(this.genericSearchService, {
              url: orgUnitViewUrl,
              staticPathParams: {
                viewName,
                orgUnitViewName: viewName,
                orgUnitId,
              },
              customMapper: unwrapSearchContent(),
            }),
            tableViewType: RegistryOfficeTransferView.HANDED_OVER_AND_STORED,
            tableId: `org-unit-transfers-${tabView}`,
          };
        case OfficerTransfersView.STORED:
          viewName = 'transfers-saved-to-desa-by-org-unit';

          return {
            tabView,
            dataSource: new IczSimpleTableDataSource(this.genericSearchService, {
              url: orgUnitViewUrl,
              staticPathParams: {
                viewName,
                orgUnitViewName: viewName,
                orgUnitId,
              },
              customMapper: unwrapSearchContent(),
            }),
            tableViewType: RegistryOfficeTransferView.HANDED_OVER_AND_STORED,
            tableId: `org-unit-transfers-${tabView}`,
          };
        case OfficerTransfersView.TO_RESOLVE_ISSUES:
          viewName = 'transfers-to-resolve-issues-by-org-unit';

          return {
            tabView,
            dataSource: new IczSimpleTableDataSource(this.genericSearchService, {
              url: orgUnitViewUrl,
              staticPathParams: {
                viewName,
                orgUnitViewName: viewName,
                orgUnitId,
              },
              customMapper: unwrapSearchContent(),
            }),
            tableViewType: RegistryOfficeTransferView.OFFICER_TO_RESOLVE_ISSUES,
            tableId: `org-unit-transfers-${tabView}`,
          };
      }
    }
    else {
      switch (tabView) {
        case OfficerTransfersView.HANDED_OVER:
          return {
            tabView,
            dataSource: new IczSimpleTableDataSource(this.genericSearchService, {
              url: ApiElasticsearchService.ElasticsearchLoadViewPath,
              staticPathParams: {
                viewName: 'transfers-handed-over',
              },
              customMapper: unwrapSearchContent(),
            }),
            tableViewType: RegistryOfficeTransferView.HANDED_OVER_AND_STORED,
            tableId: `officer-transfers-${tabView}`,
          };
        case OfficerTransfersView.STORED:
          return {
            tabView,
            dataSource: new IczSimpleTableDataSource(this.genericSearchService, {
              url: ApiElasticsearchService.ElasticsearchLoadViewPath,
              staticPathParams: {
                viewName: 'transfers-saved-to-desa',
              },
              customMapper: unwrapSearchContent(),
            }),
            tableViewType: RegistryOfficeTransferView.HANDED_OVER_AND_STORED,
            tableId: `officer-transfers-${tabView}`,
          };
        case OfficerTransfersView.TO_RESOLVE_ISSUES:
          return {
            tabView,
            dataSource: new IczSimpleTableDataSource(this.genericSearchService, {
              url: ApiElasticsearchService.ElasticsearchLoadViewPath,
              staticPathParams: {
                viewName: 'transfers-to-resolve-issues',
              },
              customMapper: unwrapSearchContent(),
            }),
            tableViewType: RegistryOfficeTransferView.OFFICER_TO_RESOLVE_ISSUES,
            tableId: `officer-transfers-${tabView}`,
          };
      }
    }
  }

}
