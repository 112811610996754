@if (dataSource) {
  <icz-documents-table
    [tableId]="tableId!"
    [viewType]="tableViewType"
    [dataSource]="dataSource"
    >
    <div tableTitle class="row grow items-center">
      <icz-label [label]="isUnitView ? 'Útvar - Spisovna' : 'Spisovna'"></icz-label>
      @if (isUnitView) {
        <icz-form-autocomplete
          class="pl-16 table-view-selector"
          [options]="selectorOptions"
          [value]="activeSelectorValue"
          (valueChange)="selectorValueChanged($event)"
          [showValidationStatus]="false"
          [clearable]="false"
        ></icz-form-autocomplete>
      }
    </div>
    <icz-tabs
      tableTabs
      class="grow"
      [tabs]="(tabs$ | async)!"
      [activeTab]="activeTab"
      [shouldNavigate]="false"
      (tabClick)="viewTabChanged($event)"
    ></icz-tabs>
    <icz-label noDataLabel label="Žádné předání na spisovnu k dispozici"></icz-label>
  </icz-documents-table>
}
