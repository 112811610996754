import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '|shared';
import {OfficerTransfersRoutingModule} from './officer-transfers-routing.module';
import {OfficerTransfersTableComponent} from './officer-transfers-table.component';

@NgModule({
  declarations: [OfficerTransfersTableComponent],
  imports: [CommonModule, SharedModule, OfficerTransfersRoutingModule],
})
export class OfficerTransfersModule {}
